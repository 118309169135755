<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/membernfc/UpdateMemberNfc"
      :submitAction="submitAction"
      finish-router="kioskMemberNfc"
      permission="Kiosk_NFC_Edit"
      @beforeSubmit="beforeSubmit"
    >
      <template #facility="scope">
        <SlotFacility
          :row-data="scope.rowData" :company-id="scope.rowData.companyID"
          :facility-id="scope.rowData.facilityID" :is-edit="scope.isEdit" :disabled="scope.rootItem.edit_disable"
        />
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"
                   :disabled="scope.rootItem.edit_disable"
        />
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :floor-id="scope.rowData.floorID" :disabled="scope.rootItem.edit_disable"/>
      </template>

      <template #bed="scope">
        <SlotBed :row-data="scope.rowData" :room-id="scope.rowData.roomID" :disabled="scope.rootItem.edit_disable"/>
      </template>

      <template #photo="scope">
        <SlotPhoto ref="photo" :row-data="scope.rowData" :is-edit="scope.isEdit"
                   :disabled="scope.rootItem.edit_disable"
        />
      </template>

      <template v-slot:inputs="scope">
        <slot-inputs
          ref="slot_inputs"
          :root-item="scope.rootItem"
          :items="row_data['nfcTags']"
          @updateValues="(value) => row_data['nfcTags'] = value"
        />
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import PageContent from '@/components/PageContent'
import SlotFacility from '@/views/member/SlotFacility'
import SlotFloor from '@/views/member/SlotFloor'
import SlotRoom from '@/views/member/SlotRoom'
import SlotBed from '@/views/member/SlotBed'
import SlotPhoto from '@/views/member/SlotPhoto'
import SlotInputs from '@/components/FieldInputs'
import _ from 'lodash'

export default {
  name: 'Edit',
  components: {
    SlotPhoto,
    SlotBed,
    SlotRoom,
    SlotFloor,
    SlotFacility,
    SlotInputs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
  },
  data() {
    return {
      title: common.getMenuName('kioskMemberNfc') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {}
    }
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/membernfc/GetMemberNfcInfo?memberID=' + this.id)
      .then(res => {
        this.row_data = res.data.member
      })
  },
  methods: {
    beforeSubmit(callback) {
      const rowData = JSON.parse(JSON.stringify(this.$refs.edit_field.rowData));
      ['birthDate', 'joinedDate'].forEach((key) => {
        if (![null, undefined].includes(rowData[key]) && isNaN(Date.parse(rowData[key]))) {
          rowData[key] = null
        }
      })
      callback(true, {
        memberID: rowData.memberID,
        nfcTags: rowData.nfcTags,
      })
    },
    submitAction(rowData, api, finishRouter) {
      this.$requestWehealth({
        method: 'post',
        url: api,
        data: rowData
      })
        .then(res => {
          this.$router.push({ name: finishRouter })
        })
    }
  }
}
</script>

<style scoped>

</style>
